import { loadLanguages } from "i18next";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Privacy = () => {
  const [activeTab, setActiveTab] = useState("satelliteKey");
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/privacy/washwoosh") {
      setActiveTab("washwoosh");
    } else if (location.pathname === "/privacy/hrms") {
      setActiveTab("hrms");
    } else if (location.pathname === "/privacy/sk-board") {
      setActiveTab("skBoard");
    } else {
      setActiveTab("satelliteKey");
    }
  }, [location, navigate]);

  return (
    <div>
      {/*--------middle-----start-------*/}
      {/*counter--start*/}
      <div className="counter-main">
        <div className="about-men-bg privacy-us-main">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="about-men"
                  data-aos="flip-left"
                  data-aos-duration={500}
                >
                  <h2>Privacy Policy </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-bottom-men">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-bottom">
                  <h6>
                    <label>
                      <Link to={"/"} onClick={handleLinkClick}>
                        Home{" "}
                      </Link>
                    </label>
                    / Privacy Policy
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter---end*/}
      <div className="about-us-main">
        <div className="row">
          <div className="col-sm-12 technology-section ">
            <div className="technology-work">
              <ul
                className="nav nav-tabs px-5 mb-5  justify-content-center"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item lg-me-3" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "satelliteKey" ? "active" : ""
                    } `}
                    id="Frontend-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Frontend"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected={activeTab === "satelliteKey"}
                    onClick={()=>navigate('/Privacy')}
                  >
                    SatelliteKey IT Solutions
                  </button>
                </li>
                <li className="nav-item lg-me-3" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "washwoosh" ? "active" : ""
                    } `}
                    id="Backend-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Backend"
                    type="button"
                    role="tab"
                    aria-controls="Backend"
                    aria-selected={activeTab === "washwoosh"}
                    onClick={()=>navigate('/privacy/washwoosh')}
                  >
                    Washwoosh
                  </button>
                </li>
                <li className="nav-item lg-me-3" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "hrms" ? "active" : ""
                    } `}
                    id="Database-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Database"
                    type="button"
                    role="tab"
                    aria-controls="Database"
                    aria-selected={activeTab === "hrms"}
                    onClick={()=>navigate('/privacy/hrms')}
                  >
                    SK HRMS
                  </button>
                </li>
                <br />
                <li className="nav-item lg-me-3" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "skBoard" ? "active" : ""
                    } `}
                    id="hosting-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#hosting"
                    type="button"
                    role="tab"
                    aria-controls="hosting"
                    aria-selected={activeTab === "skBoard"}
                    onClick={()=>navigate('/privacy/sk-board')}
                  >
                    SK Board
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                {/* SattelliteKey IT Solutions */}
                <div
                  className={`tab-pane fade ${
                    activeTab === "satelliteKey" ? "show active" : ""
                  }`}
                  id="Frontend"
                  role="tabpanel"
                  aria-labelledby="Frontend-tab"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div className="privacy-policy-text">
                          <h6>Privacy Policy for SatelliteKey IT Solutions</h6>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>1. Introduction</h6>
                          <ul>
                            <li>
                              Welcome to SatelliteKey IT Solutions (also
                              referred to below as "we," "our," "us"). At
                              SatelliteKey IT Solutions, we prioritize your
                              privacy and are dedicated to protecting your
                              personal information with the highest standards of
                              security. This Privacy Policy explains how we
                              collect, use, disclose, and safeguard your data
                              when you engage with our services, which include
                              UI/UX Design, App Development and Support, IT
                              Consulting Strategy, Custom Software Development,
                              Cloud Computing, and Digital Marketing Services.
                            </li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>2. Information We Collect</h6>
                          <p>
                            To effectively offer and enhance our range of
                            services, SatelliteKey IT Solutions collects a
                            variety of information from you, and this
                            information includes:
                          </p>
                          <ul>
                            <li>
                              <strong>
                                Personal Identification Information:{" "}
                              </strong>
                              Details such as your name, email address, phone
                              number, and other contact information. This helps
                              us communicate with you, manage your account, and
                              provide you with customer support.
                            </li>
                            <li>
                              <strong>
                                Business Information: Information:{" "}
                              </strong>
                              about your organization, including company name,
                              job title, industry, and any other relevant
                              business details. This allows us to tailor our
                              services to better meet the specific needs of your
                              business.
                            </li>
                            <li>
                              <strong>Technical Data: Includes: </strong> your
                              IP address, browser type, operating system, device
                              information, and other technical details. This
                              data is used to ensure the compatibility,
                              performance, and security of our services.
                            </li>
                            <li>
                              <strong>Service Usage Data: Information: </strong>
                              on how you interact with our services, such as the
                              pages you visit, features you use, and the
                              duration of your engagement. This data helps us
                              understand your preferences and improve our
                              service offerings.{" "}
                            </li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>3. How We Use Your Information</h6>
                          <ul>
                            <li>
                              <strong>
                                To Provide and Maintain Services:{" "}
                              </strong>
                              We utilize your information to deliver the
                              services you have requested, ensuring they are
                              executed effectively and efficiently. This
                              includes managing your account, processing
                              transactions, and addressing any technical issues
                              to guarantee seamless operation.
                            </li>
                            <li>
                              <strong>To Improve Our Services: </strong>Your
                              data helps us analyze usage patterns and identify
                              areas for enhancement. By understanding how you
                              interact with our services, we can refine our
                              offerings, introduce new features, and improve
                              overall quality to better meet your needs and
                              preferences.
                            </li>
                            <li>
                              <strong>To Communicate with You: </strong>We use
                              your contact information to respond to your
                              inquiries, provide updates about our services, and
                              keep you informed about important changes, such as
                              service updates, policy changes, or issues that
                              may affect your experience.
                            </li>
                            <li>
                              <strong>
                                To Comply with Legal Obligations:{" "}
                              </strong>
                              We use your information to adhere to legal
                              requirements and regulations, resolve disputes,
                              and ensure that we are following applicable laws.
                              This includes fulfilling legal obligations,
                              responding to legal requests, and protecting our
                              rights and interests.
                            </li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>4. Sharing Your Information</h6>
                          <p>
                            We do not sell or rent your personal information to
                            third parties. However, we may share your
                            information in the following scenarios:
                          </p>
                          <ul>
                            <li>
                              <strong>With Service Providers: </strong>We may
                              share information with third-party vendors and
                              partners who assist us in delivering our services.
                              These parties are bound by confidentiality
                              agreements and are only permitted to use your
                              information as necessary to perform their
                              functions.
                            </li>
                            <li>
                              <strong>For Legal Reasons: </strong>We may share
                              your information with government authorities or
                              regulatory agencies when we receive a legitimate
                              request or demand for such information. This can
                              include situations where we are required to
                              provide information to aid in investigations or
                              legal proceedings.
                            </li>
                            <li>
                              <strong>Business Transfers: </strong>In the event
                              of a merger, acquisition, sale, or other
                              reorganization of our business, your information
                              may be transferred to the new entity as part of
                              the transaction. Such transfers will be governed
                              by the terms of this Privacy Policy, and we will
                              ensure that the new entity adheres to similar
                              privacy and data protection standards.
                            </li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>5. Data Security</h6>
                          <p>
                            We implement appropriate technical and
                            organizational measures to protect your personal
                            information against unauthorized access, alteration,
                            disclosure, or destruction. Despite these measures,
                            no security system is impenetrable, and we cannot
                            guarantee the absolute security of your information.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>6. Data Retention</h6>
                          <p>
                            We retain your information only as long as necessary
                            to fulfill the purposes outlined in this Privacy
                            Policy, comply with legal obligations, and resolve
                            disputes. Once your information is no longer needed,
                            we will securely delete or anonymize it.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>7. Your Rights and Choices</h6>
                          <p>
                            Depending on your jurisdiction, you may have certain
                            rights regarding your personal information,
                            including:
                          </p>
                          <ul>
                            <li>
                              <strong>Access and Correction: </strong>You have
                              the right to request access to your personal
                              information that we hold and to request correction
                              of any inaccuracies. To do so, please contact us
                              at [info@satellitekeyit.com], and we will provide
                              you with the necessary instructions to facilitate
                              your request.
                            </li>
                            <li>
                              <strong>Data Portability:</strong>You may request
                              a copy of your personal information in a
                              structured, commonly used format.
                            </li>
                            <li>
                              <strong>Withdrawal of Consent: </strong>we rely on
                              your consent to process your personal information,
                              you have the right to withdraw that consent at any
                              time. Please note that withdrawing consent may
                              affect our ability to provide certain services.
                            </li>
                            <li>
                              <strong>Opt-Out of Marketing: </strong>You can
                              opt-out of receiving marketing communications from
                              us at any time. To unsubscribe, you can use the
                              opt-out link included in our marketing emails or
                              <a
                                href="https://satellitekeyit.com/contact"
                                target="_blank"
                              >
                                {" "}
                                contact us
                              </a>
                              . We will promptly honor your request and remove
                              you from our marketing lists.
                            </li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>8. Third-Party Links</h6>
                          <p>
                            Our website and services may contain links to
                            third-party websites or services. We are not
                            responsible for the privacy practices or content of
                            these third parties. We encourage you to review the
                            privacy policies of any third-party sites you visit.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>9. Children's Privacy</h6>
                          <p>
                            Our services are not intended for individuals under
                            the age of 18. We do not knowingly collect or
                            solicit personal information from children under 18.
                            If we become aware that we have collected personal
                            information from a child under 18, we will take
                            steps to delete such information.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>10. Changes to This Privacy Policy</h6>
                          <p>
                            We may update this Privacy Policy from time to time
                            to reflect changes in our practices or legal
                            requirements. We will notify you of significant
                            changes by posting the revised policy on our website
                            with an updated effective date.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>11. Contact Us</h6>
                          <p>
                            If you have any questions, concerns, or requests
                            regarding this Privacy Policy or our data practices,
                            please contact us at:{" "}
                            <a href="mailto:info@satellitekeyit.com">
                              info@satellitekeyit.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Washwoosh */}
                <div
                  className={`tab-pane fade ${
                    activeTab === "washwoosh" ? "show active" : ""
                  }`}
                  id="Backend"
                  role="tabpanel"
                  aria-labelledby="Backend-tab"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div className="privacy-policy-text">
                          <h6>Privacy Policy for Washwoosh</h6>
                          <p className="text-secondary">
                            We’re on a mission to deliver engaging, curated
                            courses at a reasonable price.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>1. Payments</h6>
                          <ul>
                            <li>
                              You agree to pay a one-time activation fee and a
                              per-message rate to use the Services, with
                              payments processed through your established
                              payment method as per your Support Agreement. You
                              commit to maintaining WashWoosh’s authorization to
                              charge your selected payment method until all fees
                              are paid in full, unless mutually agreed otherwise
                              in writing. Real-time account status regarding
                              text messages sent can be checked via Car Wash
                              Administrator. Failure to pay fees may result in
                              service interruption until all outstanding amounts
                              are settled. WashWoosh is not liable for payment
                              method errors or failures, including refusals to
                              authorize charges. Customized plans may offer
                              individual terms and billing cycles at our
                              discretion, not publicly listed on our site.
                            </li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>2. Acceptable Use</h6>
                          <p>
                            WashWoosh reserves the right to assess and determine
                            violations of its acceptable use policy and Terms at
                            its discretion. Acceptable use of our Services
                            encompasses activities such as promoting car wash
                            discounts, unlimited packages, or referral programs,
                            strictly for lawful purposes. Prohibited activities
                            include actions that could adversely impact the
                            availability, reliability, or stability of
                            WashWoosh's Services, attempting to circumvent
                            security measures, or engaging in illegal activities
                            such as fraud. Violations also extend to misuse of
                            copyrighted or trademarked material, unauthorized
                            data collection, and sending unsolicited marketing
                            messages. Any misuse or illegal activity may result
                            in immediate termination of Services and may be
                            subject to legal consequences under applicable laws.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>3. Warranty Disclaimer</h6>
                          <p>
                            WashWoosh provides the services and all related
                            content on an “as is” basis. washWoosh makes no
                            representations or warranties of any kind, whether
                            express, implied, or statutory. to the broadest
                            extent permitted by law, washWoosh disclaims all
                            warranties, including and without limitation, any
                            implied warranties of merchantability, satisfactory
                            quality, fitness for any particular purpose, title,
                            and quiet enjoyment and any warranties arising out
                            of any course of dealing or usage of trade with
                            respect to the services. no verbal advice or written
                            information given by washWoosh, its employees,
                            licensors, licensees, affiliates, or agents or by
                            any other third party shall create any warranty with
                            respect to the services.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>4. Indemnification</h6>
                          <p>
                            In addition to your obligations under your Support
                            Agreement with WashWoosh, to the fullest extent
                            allowed by applicable law, You hereby agree to
                            defend, indemnify, and hold harmless WashWoosh and
                            its business and technology partners, underlying
                            technology creators, third-party suppliers,
                            operators and providers, licensors, board members,
                            officers, directors, shareholders, employees,
                            distributors, resellers, affiliates, and agents from
                            and against any damages (whether compensatory,
                            exemplary, punitive, special, consequential, or
                            incidental), losses, liabilities, judgments, fines,
                            settlements, and expenses (including reasonable
                            attorney, expert, and consultant fees and other
                            legal expenses) in connection with any and all
                            claims, allegations, governmental inquiries,
                            demands, causes of action, lawsuits, or proceedings
                            relating to or arising out of or in connection with
                            Your use of the Services, including without
                            limitation any claim or action arising out of or in
                            connection with (i) any alleged act or omission by
                            You that would constitute a breach of these Terms;
                            (ii) any violation of any law, rule, or regulation
                            concerning messaging alleged to have been committed
                            through any use of your WashWoosh account or your
                            use the Services; or (iii) any other use of
                            WashWoosh's Services by You in any manner not
                            authorized by these Terms, in violation of the
                            restrictions herein, or in violation of applicable
                            law.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>5. Termination</h6>
                          <p>
                            You may terminate your use of the Services at any
                            time by submitting a support ticket through
                            WashWoosh’s user interface. WashWoosh requires a
                            30-day termination notice. Service may not be
                            cancelled by any other method such as phone, email,
                            or letter. Lack of activity alone does not
                            automatically terminate your account, and you are
                            responsible for service fees until you expressly
                            cancel your account or until your data has been
                            purged (whichever occurs first). In the absence of
                            an explicit cancellation request by support ticket,
                            you agree to pay any and all applicable fees through
                            the date of WashWoosh's termination of your account
                            access. You understand and agree that WashWoosh will
                            make no refund of any fees whether or not the
                            Services have been used. At any time, with or
                            without notice, WashWoosh may terminate your use of
                            the Services or disable your account in whole or in
                            part at WashWoosh's sole discretion. WashWoosh shall
                            bear no liability to you or any third party because
                            of any such action.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Hrms */}
                <div
                  className={`tab-pane fade ${
                    activeTab === "hrms" ? "show active" : ""
                  }`}
                  id="Database"
                  role="tabpanel"
                  aria-labelledby="Database-tab"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div className="privacy-policy-text">
                          <h6>Privacy Policy for SK HRMS</h6>
                          <p className="text-secondary">
                            SK HRMS values your privacy and is committed to
                            providing exceptional service while ensuring that
                            your personal and business information remains
                            secure. We use reasonable measures to protect your
                            data from loss, misuse, unauthorized access,
                            disclosure, alteration, or destruction. This Privacy
                            Policy will help you understand what information we
                            collect, how it is used, and how we protect it to
                            provide you with the best possible service. This
                            Privacy Policy applies to your use of our websites
                            as well as the product lines and services we offer,
                            including, but not limited to, applications released
                            by us through the Apple App Store or the Google Play
                            Store (collectively “Website” or “Websites”). SK
                            HRMS collectively referred to as “SK HRMS,” “we,”
                            “us,” or “our”.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>1. Introduction</h6>
                          <ul>
                            <li>
                              Our product is a comprehensive project management
                              system where we can effectively manage and track
                              the progress of the project with the respect of
                              each user. It gives user the right to create task,
                              edit and change the status of the task through
                              various stages.
                            </li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>2. Key Components:</h6>

                          <ul>
                            <li>
                              <strong>Task Creation and Assignment: </strong>
                              The user can create task, define its details such
                              as priority, due date, estimated time, tracker
                              etc.
                            </li>
                            <li>
                              <strong>Task Status Management:</strong>
                              The task will go through various stages after
                              creation to reflect their progress.
                            </li>
                            <li>
                              <strong>Ready: </strong> your The task has been
                              assigned to the user but hasn’t been started yet,
                            </li>
                            <li>
                              <strong>Doing: </strong>
                              The user has started doing the task.
                            </li>
                            <li>
                              <strong>Review: </strong>
                              The user has completed the task and is currently
                              under review stage.
                            </li>
                            <li>
                              <strong>Done: </strong>
                              The task has been completed and reviewed.
                            </li>
                            <li>
                              <strong>Backlog: </strong>
                              The task has been postponed.
                            </li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>3. Task Editing and Reassignment:</h6>
                          <p>
                            Users have the capability to modify tasks as
                            necessary, including updating details or reassigning
                            them to different team members. This flexibility
                            ensures that workflows remain adaptable to the
                            evolving nature of projects.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>4. Project and Task Tracking:</h6>
                          <p>
                            The application serves as a centralized platform for
                            monitoring all active projects and their
                            corresponding tasks. Users can easily identify
                            pending tasks, those in progress, and completed
                            items, thereby fostering transparency within the
                            team.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>5. Communication: </h6>
                          <p>
                            The integrated chat functionality facilitates
                            discussions around specific tasks, enables the
                            sharing of updates, and allows for inquiries,
                            thereby promoting effective communication among team
                            members.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>6. DISCLAIMER</h6>
                          <p>
                            SK HRMS believes the information in this Privacy
                            Policy is accurate and reliable but presents it
                            without guarantee, expressed or implied.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>7. INFORMATION COLLECTION AND USE</h6>
                          <p>SK HRMS collects personal information when:</p>
                          <ul>
                            <li>
                              You visit our website or use our mobile
                              applications.
                            </li>
                            <li>You register to access our services.</li>
                            <li>
                              You request information or support from SK HRMS.
                            </li>
                            <li>
                              You voluntarily provide information via the
                              application.
                            </li>
                            <li>
                              Your employer provides data to facilitate the use
                              of our services.
                            </li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>
                            8. Personal Information collected may include:
                          </h6>
                          <ul>
                            <li>Name</li>
                            <li>Date of birth</li>
                            <li>Email address</li>
                            <li>Phone number</li>
                            <li>Employer details</li>
                            <li>Job title and employment history</li>
                            <li>Profile photo (if uploaded)</li>
                            <li>Task-related data</li>
                            <li>IP address and device identification</li>
                            <li>Communication exchanged via the app</li>
                            <li>Geolocation data</li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>9. HOW WE USE YOUR INFORMATION</h6>
                          <p>We use the collected information to:</p>
                          <ul>
                            <li>Provide and enhance our services.</li>
                            <li>Assign and track task progress.</li>
                            <li>Enable communication within the app.</li>
                            <li>Analyze app performance and user behavior.</li>
                            <li>Send service-related updates and reminders.</li>
                            <li>Respond to customer support inquiries.</li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>10. SHARING OF INFORMATION</h6>
                          <p>
                            We may share your personal information with third
                            parties under the following circumstances:
                          </p>
                          <ul>
                            <li>
                              With your employer to facilitate task management
                              services.
                            </li>
                            <li>
                              With service providers who assist in operations
                              such as analytics and customer support.
                            </li>
                            <li>
                              As required by law, to comply with legal
                              processes.
                            </li>
                            <li>
                              In connection with mergers, acquisitions, or other
                              business transactions.
                            </li>
                          </ul>
                          <p>
                            We do not sell, rent, loan, or disclose personal
                            information except as outlined in this Privacy
                            Policy.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>11. SECURITY MEASURES</h6>
                          <p>
                            We employ various security measures to protect your
                            personal information, including:
                          </p>
                          <ul>
                            <li>
                              Encryption technologies to secure data
                              transmission.
                            </li>
                            <li>Secure server infrastructure.</li>
                            <li>
                              Access controls that limit access to authorized
                              personnel only.
                            </li>
                            <li>Regular security audits and monitoring.</li>
                          </ul>
                          <p>
                            However, please be aware that no security measures
                            can guarantee 100% protection. It is important that
                            you maintain the confidentiality of your login
                            credentials.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>12. DATA RETENTION AND DELETION</h6>

                          <ul>
                            <li>
                              Data is retained for a period of 30 days to
                              analyze user efficiency.
                            </li>
                            <li>
                              Personal data may be deleted upon request unless
                              required to comply with legal obligations.
                            </li>
                            <li>
                              Employer-related data is retained based on company
                              policies.
                            </li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>13. COOKIES AND TRACKING TECHNOLOGIES</h6>
                          <p>
                            We use cookies and tracking technologies to enhance
                            user experience, including:
                          </p>
                          <ul>
                            <li>Performance tracking.</li>
                            <li>User authentication.</li>
                            <li>Analytics and improvements.</li>
                          </ul>
                          <p>
                            You may control the use of cookies through your
                            browser settings.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>14. CHILDREN'S PRIVACY</h6>
                          <p>
                            Our services are not intended for individuals under
                            the age of 18. We do not knowingly collect personal
                            data from minors. If we become aware of such data,
                            it will be deleted promptly.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>15. USER RIGHTS AND CHOICES</h6>
                          <p>You have the right to:</p>
                          <ul>
                            <li>
                              Access and update your personal information.
                            </li>
                            <li>Request the deletion of your data.</li>
                            <li>Opt-out of marketing communications.</li>
                            <li>Withdraw consent for data processing.</li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>16. CHANGES TO THIS PRIVACY POLICY</h6>
                          <p>
                            SK HRMS may update this Privacy Policy periodically.
                            Any changes will be posted on our website, and we
                            encourage users to review it regularly.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>17. CONTACT INFORMATION</h6>
                          <p>
                            For inquiries or concerns regarding this Privacy
                            Policy, please contact us at:{" "}
                            <a href="mailto:info@satellitekeyit.com">
                              info@satellitekeyit.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* sk board */}
                <div
                  className={`tab-pane fade ${
                    activeTab === "skBoard" ? "show active" : ""
                  }`}
                  id="hosting"
                  role="tabpanel"
                  aria-labelledby="hosting-tab"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div className="privacy-policy-text">
                          <h6>Privacy Policy for SK Board</h6>
                          <p className="text-secondary">
                            SK Board values your privacy and is committed to
                            providing exceptional service while ensuring that
                            your personal and business information remains
                            secure. We use reasonable measures to protect your
                            data from loss, misuse, unauthorized access,
                            disclosure, alteration, or destruction. This Privacy
                            Policy will help you understand what information we
                            collect, how it is used, and how we protect it to
                            provide you with the best possible service. This
                            Privacy Policy applies to your use of our websites
                            as well as the product lines and services we offer,
                            including, but not limited to, applications released
                            by us through the Apple App Store or the Google Play
                            Store (collectively “Website” or “Websites”). SK
                            Board collectively referred to as “SKBoard,” “we,”
                            “us,” or “our”.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>1. Introduction</h6>
                          <ul>
                            <li>
                              Our product is a comprehensive project management
                              system where we can effectively manage and track
                              the progress of the project with the respect of
                              each user. It gives user the right to create task,
                              edit and change the status of the task through
                              various stages.
                            </li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>2. Key Components:</h6>

                          <ul>
                            <li>
                              <strong>Task Creation and Assignment: </strong>
                              The user can create task, define its details such
                              as priority, due date, estimated time, tracker
                              etc.
                            </li>
                            <li>
                              <strong>Task Status Management:</strong>
                              The task will go through various stages after
                              creation to reflect their progress.
                            </li>
                            <li>
                              <strong>Ready: </strong> your The task has been
                              assigned to the user but hasn’t been started yet,
                            </li>
                            <li>
                              <strong>Doing: </strong>
                              The user has started doing the task.
                            </li>
                            <li>
                              <strong>Review: </strong>
                              The user has completed the task and is currently
                              under review stage.
                            </li>
                            <li>
                              <strong>Done: </strong>
                              The task has been completed and reviewed.
                            </li>
                            <li>
                              <strong>Backlog: </strong>
                              The task has been postponed.
                            </li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>3. Task Editing and Reassignment:</h6>
                          <p>
                            Users have the capability to modify tasks as
                            necessary, including updating details or reassigning
                            them to different team members. This flexibility
                            ensures that workflows remain adaptable to the
                            evolving nature of projects.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>4. Project and Task Tracking:</h6>
                          <p>
                            The application serves as a centralized platform for
                            monitoring all active projects and their
                            corresponding tasks. Users can easily identify
                            pending tasks, those in progress, and completed
                            items, thereby fostering transparency within the
                            team.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>5. Communication: </h6>
                          <p>
                            The integrated chat functionality facilitates
                            discussions around specific tasks, enables the
                            sharing of updates, and allows for inquiries,
                            thereby promoting effective communication among team
                            members.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>6. DISCLAIMER</h6>
                          <p>
                            SK Board believes the information in this Privacy
                            Policy is accurate and reliable but presents it
                            without guarantee, expressed or implied.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>7. INFORMATION COLLECTION AND USE</h6>
                          <p>SK Board collects personal information when:</p>
                          <ul>
                            <li>
                              You visit our website or use our mobile
                              applications.
                            </li>
                            <li>You register to access our services.</li>
                            <li>
                              You request information or support from SK Board.
                            </li>
                            <li>
                              You voluntarily provide information via the
                              application.
                            </li>
                            <li>
                              Your employer provides data to facilitate the use
                              of our services.
                            </li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>
                            8. Personal Information collected may include:
                          </h6>
                          <ul>
                            <li>Name</li>
                            <li>Date of birth</li>
                            <li>Email address</li>
                            <li>Phone number</li>
                            <li>Employer details</li>
                            <li>Job title and employment history</li>
                            <li>Profile photo (if uploaded)</li>
                            <li>Task-related data</li>
                            <li>IP address and device identification</li>
                            <li>Communication exchanged via the app</li>
                            <li>Geolocation data</li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>9. HOW WE USE YOUR INFORMATION</h6>
                          <p>We use the collected information to:</p>
                          <ul>
                            <li>Provide and enhance our services.</li>
                            <li>Assign and track task progress.</li>
                            <li>Enable communication within the app.</li>
                            <li>Analyze app performance and user behavior.</li>
                            <li>Send service-related updates and reminders.</li>
                            <li>Respond to customer support inquiries.</li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>10. SHARING OF INFORMATION</h6>
                          <p>
                            We may share your personal information with third
                            parties under the following circumstances:
                          </p>
                          <ul>
                            <li>
                              With your employer to facilitate task management
                              services.
                            </li>
                            <li>
                              With service providers who assist in operations
                              such as analytics and customer support.
                            </li>
                            <li>
                              As required by law, to comply with legal
                              processes.
                            </li>
                            <li>
                              In connection with mergers, acquisitions, or other
                              business transactions.
                            </li>
                          </ul>
                          <p>
                            We do not sell, rent, loan, or disclose personal
                            information except as outlined in this Privacy
                            Policy.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>11. SECURITY MEASURES</h6>
                          <p>
                            We employ various security measures to protect your
                            personal information, including:
                          </p>
                          <ul>
                            <li>
                              Encryption technologies to secure data
                              transmission.
                            </li>
                            <li>Secure server infrastructure.</li>
                            <li>
                              Access controls that limit access to authorized
                              personnel only.
                            </li>
                            <li>Regular security audits and monitoring.</li>
                          </ul>
                          <p>
                            However, please be aware that no security measures
                            can guarantee 100% protection. It is important that
                            you maintain the confidentiality of your login
                            credentials.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>12. DATA RETENTION AND DELETION</h6>

                          <ul>
                            <li>
                              Data is retained for a period of 30 days to
                              analyze user efficiency.
                            </li>
                            <li>
                              Personal data may be deleted upon request unless
                              required to comply with legal obligations.
                            </li>
                            <li>
                              Employer-related data is retained based on company
                              policies.
                            </li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>13. COOKIES AND TRACKING TECHNOLOGIES</h6>
                          <p>
                            We use cookies and tracking technologies to enhance
                            user experience, including:
                          </p>
                          <ul>
                            <li>Performance tracking.</li>
                            <li>User authentication.</li>
                            <li>Analytics and improvements.</li>
                          </ul>
                          <p>
                            You may control the use of cookies through your
                            browser settings.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>14. CHILDREN'S PRIVACY</h6>
                          <p>
                            Our services are not intended for individuals under
                            the age of 18. We do not knowingly collect personal
                            data from minors. If we become aware of such data,
                            it will be deleted promptly.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>15. USER RIGHTS AND CHOICES</h6>
                          <p>You have the right to:</p>
                          <ul>
                            <li>
                              Access and update your personal information.
                            </li>
                            <li>Request the deletion of your data.</li>
                            <li>Opt-out of marketing communications.</li>
                            <li>Withdraw consent for data processing.</li>
                          </ul>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>16. CHANGES TO THIS PRIVACY POLICY</h6>
                          <p>
                            SK Board may update this Privacy Policy
                            periodically. Any changes will be posted on our
                            website, and we encourage users to review it
                            regularly.
                          </p>
                        </div>
                        <div className="privacy-policy-text1">
                          <h6>17. CONTACT INFORMATION</h6>
                          <p>
                            For inquiries or concerns regarding this Privacy
                            Policy, please contact us at:{" "}
                            <a href="mailto:info@satellitekeyit.com">
                              info@satellitekeyit.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*about-us--start */}

      {/*about-us-end*/}
      {/*----middle-----end--------------------*/}
    </div>
  );
};

export default Privacy;
